<template>
  <section>
    <a-divider class="dividerTitle text-outline">{{
      $t("pages.home.title")
    }}</a-divider>

    <div class="notification-text">
      <a-alert
        :message="$t('pages.home.notification-title')"
        :description="$t('pages.home.notification-description')"
        type="info"
        show-icon
      />
    </div>

    <div class="container">
      <span class="subtitle text-outline" style="padding: 5px">{{
        $t("pages.home.subtitle")
      }}</span>

      <div class="tests-all">
        <span v-for="item in listData" :key="item.systemname" class="test-span">
          <a-card
            hoverable
            class="test-card"
            @click="handleTestStartClick(item)"
          >
            <template slot="cover">
              <img
                class="test-image"
                :alt="$i18n.getTranslation(item.data.name)"
                :src="$strapi.storageUrl + item.image.url"
              />
            </template>
            <div class="card-description">
              {{ $i18n.getTranslation(item.data.description) }}
            </div>
            <template slot="title">{{
              $i18n.getTranslation(item.data.name)
            }}</template>
          </a-card>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  middleware: "uiDefaultState",
  // layout: ({ isMobile }) => isMobile ? 'mobile' : 'default',
  components: {},
  async fetch({ store }) {
    await store.dispatch("tests/fetchTestsList");
  },
  data() {
    return {
      listData: [],
      pagination: {
        onChange: () => {},
        pageSize: 3,
      },
      actions: [
        { type: "eye-o", text: "~" },
        { type: "like-o", text: "~" },
      ],
    };
  },
  computed: {
    authId() {
      return this.id;
    },
    testsList() {
      return this.getTestsList;
    },
    ...mapGetters([
      "getTestsList",
      // ...
    ]),
  },
  async mounted() {
    // console.log(this.$moment);
    // console.log(this.$strapi.storageUrl);
    // console.log('$strapi.storageUrl', this.$strapi.storageUrl);
    this.listData = await this.$store.dispatch("tests/fetchTestsList"); //this.testsList;
    // console.log(this.listData);
    if (this.listData) {
      for (let i = 0; i < this.listData.length; i++) {
        this.listData[i].index = i;
      }
      // this.$nextTick(() => {
      //   this.$loading.start();
      //   setTimeout(() => this.$loading.finish(), 500);
      // });
    }

    //console.log("listData");
    //console.log(this.listData);
  },
  methods: {
    handleTestStartClick(item) {
      const url =
        item.type === "Szondi"
          ? "/tests/szondi" // :id?' + item.id
          : item.type === "Quadro"
          ? "/tests/quadro" // :id?' + item.id
          : "";
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 20px;
  margin-top: 0px; // 30px
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.list-item-meta-custom {
  margin-top: 30px;
  font-size: x-large; // xx-large
}

.ant-list-item-content,
.ant-list-item-meta-description,
.ant-list-item-meta-title {
  text-align: center !important;
  font-weight: 600;
}
.ant-list-item-action .li {
  width: 100%;
}

.tests-list {
  margin: 20px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tests-all {
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: row;

  margin-top: 20px;
}
.test-span {
  padding: 20px;
}

.test-card {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  max-width: 310px;

  padding: 0 25px;

  display: flex;
  flex-direction: column;
}

.test-image {
  width: 100%;
  height: 100%;
  object-fit: contain;

  margin-left: auto;
  margin-right: auto;
}

.card-description {
  font-weight: bold;
  font-style: italic;
}

@media only screen and (max-width: 700px) {
  .right-image {
    display: none;
  }
  #a-list-item {
    max-width: 400px;
  }
}

.notification-text {
  max-width: 800px;
  margin: 30px auto;
  width: 80%;
}
</style>
